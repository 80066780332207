<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66667 10.4998H8.75C9.60867 10.4998 10.3752 10.956 10.9095 11.6677C11.2887
      10.963 11.76 10.3108 12.299 9.72867C11.3622 8.7685 10.122 8.1665 8.75
      8.1665H4.66667C4.0215 8.1665 3.5 8.68917 3.5 9.33317C3.5 9.97717 4.0215 10.4998
      4.66667 10.4998Z"
      fill="#1976D2"
    />
    <path
      d="M13.6325 14.1294C14.2753 12.1962 16.2645 10.4999 17.8897 10.4999H20.034L18.5255
      12.0084C18.0693 12.4646 18.0693 13.2019 18.5255 13.6581C18.753 13.8856 19.0517 13.9999
      19.3503 13.9999C19.649 13.9999 19.9477 13.8856 20.1752 13.6581L24.5 9.33322L20.1752
      5.00838C19.719 4.55222 18.9817 4.55222 18.5255 5.00838C18.0693 5.46455 18.0693 6.20188
      18.5255 6.65805L20.034 8.16655H17.8897C15.2367 8.16655 12.3935 10.4614 11.4182
      13.3921L10.8675 15.0382C10.1197 17.2852 8.45017 18.6666 7.58333 18.6666H4.66667C4.0215
      18.6666 3.5 19.1892 3.5 19.8332C3.5 20.4772 4.0215 20.9999 4.66667
      20.9999H7.58333C9.7265 20.9999 12.0902 18.7541 13.0818 15.7744L13.6325 14.1294Z"
      fill="#1976D2"
    />
    <path
      d="M18.5255 15.5084C18.0693 15.9646 18.0693 16.7019 18.5255 17.1581L20.034
      18.6666H17.3063C15.827 18.6666 14.588 17.6271 14.1668 16.2072C13.8682 17.0822
      13.4353 17.9561 12.8952 18.7564C13.9288 20.1121 15.512 20.9999 17.3063
      20.9999H20.034L18.5255 22.5084C18.0693 22.9646 18.0693 23.7019 18.5255 24.1581C18.753
      24.3856 19.0517 24.4999 19.3503 24.4999C19.649 24.4999 19.9477 24.3856 20.1752
      24.1581L24.5 19.8332L20.1752 15.5084C19.719 15.0522 18.9817 15.0522 18.5255 15.5084Z"
      fill="#1976D2"
    />
  </svg>
</template>

<script>
export default {

}
</script>
