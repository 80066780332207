var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-0 p-0 w-full h-full"},[_c('HistoryAppBar',{attrs:{"headers":_vm.headers},on:{"get-call-records":function($event){return _vm.updateDate($event)},"search-update":function($event){return _vm.updateSearch($event)},"sort-update":function($event){return _vm.updateSort($event)}}}),(!_vm.$vuetify.breakpoint.xs)?_c('v-data-table',{staticClass:"call-history",attrs:{"fixed-header":"","items":_vm.callHistoryItems || [],"loading":_vm.loading,"headers":_vm.headers,"footer-props":_vm.dataTableFooterProps,"options":_vm.dataTableOptions,"server-items-length":_vm.callHistoryPagination.itemsTotal},on:{"update:options":function($event){_vm.dataTableOptions=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.fetchDataError === 405)?_c('p',{staticClass:"error--text mt-2"},[_vm._v(" "+_vm._s(_vm.$t("errors.not_allowed_page.description"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("errors.not_allowed_page.hint"))+" ")]):_vm._e(),(_vm.fetchDataError)?_c('v-btn',{staticClass:"contacts__refresh-btn",attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.fetchData()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $refresh ")]),_vm._v(" "+_vm._s(_vm.$t('button.refresh'))+" ")],1):_c('EmptyContent',{attrs:{"title":_vm.$t('errors.call_history_empty')}})]},proxy:true},{key:"no-results",fn:function(){return [_c('p',{staticClass:"contacts__message"},[_vm._v(" "+_vm._s(_vm.$t('data.no_results'))+" ")])]},proxy:true},{key:"item.called_calling",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"title":_vm.$options.filters.getDirectionTitle(item),"color":_vm.$options.filters.getIconColor(item)}},[_vm._v(" "+_vm._s(_vm._f("getDirectionIcon")(item))+" ")]),_c('span',{staticClass:"font-bold ml-4"},[_vm._v(" "+_vm._s(item.contactInfo.name)+" ")])]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"call-history__connect-time",class:[!_vm.$vuetify.breakpoint.xs? 'ml-5': 'ml-0']},[_vm._v(" "+_vm._s(item.contactInfo.number || item.contactInfo.number_ext)+" ")])]}},{key:"item.connect_time",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"call-history__connect-time",class:[!_vm.$vuetify.breakpoint.xs? 'ml-5': 'ml-0']},[_vm._v(" "+_vm._s(_vm._f("convertToCalendar")(item.connect_time))+" ")])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [(item.duration > 0 && item.status === 'accepted')?_c('span',{staticClass:"call-history__duration"},[_vm._v(" "+_vm._s(_vm.$t('call.duration'))+": "+_vm._s(_vm._f("formatPrettySeconds")(item.duration))+" ")]):_c('span',{staticClass:"call-history__duration"},[_vm._v(" "+_vm._s(_vm._f("getDirectionTitle")(item))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.recording_id && !_vm.$_breakpoints_mobile)?_c('v-progress-linear',{staticClass:"mr-4",attrs:{"value":_vm.playProgress[item.recording_id] || 0}}):_vm._e(),(item.recording_id)?_c('PlayBtn',{attrs:{"call-id":item.recording_id},on:{"update-progress":function($event){return _vm.updatePlayProgress($event)}}}):_vm._e(),(item.recording_id)?_c('DownloadBtn',{attrs:{"call-id":item.recording_id,"filename":_vm.$_calls_getFilename(item)}}):_vm._e(),_c('AudioCallBtn',{staticClass:"ml-auto",attrs:{"btn-color":"transparent","icon-color":"accent","contact":item.contactInfo}}),_c('VideoCallBtn',{attrs:{"btn-color":"transparent","icon-color":"accent","contact":item.contactInfo}})]}}],null,true)}):_c('HistoryList',{attrs:{"items":_vm.callHistoryItems || [],"sort":_vm.sort,"page-length":_vm.pageLength,"current-page":_vm.dataTableOptions.page,"loading":_vm.loading},on:{"update-page":function($event){return _vm.updatePage($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }